function setEqualHeight() {
    $(".grid-element-title-wrapper").matchHeight();
    $(".grid-element-content-detail").matchHeight();
    $(".product-grid-product .product-title").matchHeight();

}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
    // $.fn.matchHeight._update();
});

/*
var $animation_elements = $(".animation-element"),
$window = $(window);
$in_view_timeout = 500;

function set_in_view(el, i, window_height, window_top_position, window_bottom_position) {
	if (!isNumeric(i)) {
		i = 1;
	};
	var $el = $(this),
	element_height = el.outerHeight(),
	element_top_position = el.offset().top,
	element_bottom_position = (element_top_position + element_height);
	if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
		i++;
		setTimeout(function () {
			el.addClass("in-view");
		},
			i * $in_view_timeout);
	} else {
		el.removeClass("in-view");
	}
	return i;
};

function check_if_in_view() {
	var window_height = $window.height(),
	window_top_position = $window.scrollTop(),
	window_bottom_position = window_top_position + window_height,
	i = 0,
	el;
    */
/*
$.each($animation_elements, function () {
el = $(this);
i = set_in_view(el,i,window_height,window_top_position,window_bottom_position);
});
 */
/*
	el = $("#o-nas-1");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
	el = $("#o-nas-2");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
	el = $("#o-nas-3");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
};

$window.on("scroll resize", check_if_in_view);
$window.trigger("scroll");
*/

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(document).ready(function() {
    var width, height;
    $(".widget-photo-lightbox").hover(function() {
        width = $(this).width();
        height = $(this).height();
        $(this).css("width", width + "px");
        $(this).css("height", height + "px");
        $(this).find(".overlay").css("width", width + "px");
        $(this).find(".overlay").css("height", height + "px");
        console.log("width = " + width);
    }, function() {
        $(this).css("width", "");
        $(this).css("height", "");
    });
});
